.chats {
  padding: 32px;
}

.chats h1 {
  margin-top: 0;
}

.chats .chat {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #002f52;
  padding: 12px;
}

.TableContainer {
  overflow-x: auto;
  overflow-y: auto;
}

@media (max-width: 1400px) {
  .MuiContainer-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .MuiTableCell-root {
    padding: 2px !important;
    font-size: 0.675rem !important;
  }
}
