.InclusaoMenus {
  padding: 32px;
}

.InclusaoMenus h1 {
  margin-top: 0;
}

.InclusaoMenus .InclusaoMenu {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #002f52;
  padding: 12px;
}
