hr {
  margin: 0;
}

.rodape {
  background-color: var(--branco);
  padding: 0px;
  background: #f9f9f9;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.lista-rodape {
  display: none;
  border-left: 1px solid #858585;
  padding-left: 20px;
}

.lista-rodape-vazia {
  display: none;
  border-left: 0px solid #858585;
  padding-left: 20px;
}

@media screen and (min-width: 1024px) {
  .rodape {
    display: flex;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .lista-rodape {
    display: block;
  }

  .lista-rodape__item {
    display: flex;
    align-items: center;
    margin: 0.3em 0;
  }

  .lista-rodape__link {
    text-decoration: none;
    margin-left: 0.3em;
    color: #474646;
  }

  .lista-rodape__titulo {
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
    color: #858585;
  }

  .rodape__titulo {
    font-size: 10px;
  }
}

@media screen and (min-width: 1728px) {
  .lista-rodape {
    padding-left: 1em;
  }

  .rodape {
    padding: 0px 0;
    display: flex;
    vertical-align: bottom;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
}
